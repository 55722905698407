
body{
    background-color: rgba(0, 0, 0, 0.942);
}
.container{
 display: flex;
 justify-content: center;
 padding-top: 7px;
 padding-bottom: 7px;
 margin: auto;
 border: 2px solid rgb(158, 158, 158);
 border-radius: 15px;
 width: 50vw;
 box-shadow: 7px 7px 7px gray;
}
.table{
    list-style: none;
}
.table .item{
    font-size: 20px;
    margin-top: 8px;
    color: rgb(198, 198, 198);
}
h2{
    color: rgb(211, 211, 211);;
}
.table .item:hover{
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 2px 2px 2px gray;
}
.purple{
    background-image: linear-gradient(to top right ,rgba(175, 82, 222, 1),transparent);
    position: absolute;
    top: 5rem;
    left: 0px;
    width: 49vw;
    height: 90vh;
    filter: blur(100px);
    z-index: -1;;
}
.pink{
    background-image: linear-gradient(to top left ,rgba(255, 55, 95, 1),transparent);
    position: absolute;
    top: 5rem;
    right: 0px;
    width: 50vw;
    height: 90vh;
    filter: blur(100px);
    z-index: -1;
}